// @ts-nocheck
import React from 'react'
import { Svg, G, Path } from '@react-pdf/renderer'

interface Props {
  color?: string
  width?: number
  height?: number
}

export const TenYearIcon: React.FC<Props> = ({ color = '#1d1d1b', width = 25, height = 25 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 283.46 283.46">
      <G>
        <Path
          fill={color}
          d="M240.2,51.52c-19.03-24.91-50.67-37.02-96.72-37.02s-77.69,12.11-96.72,37.02c-.2.26-.39.53-.59.79-19.8,22.79-25.4,55.57-25.4,92.01,0,68.83,13.38,124.63,117.91,124.63,32.72,0,56.51-5.47,73.75-15.09,12.66-5.99,22.7-14.14,30.26-24.52,9.89-13.6,20.01-34.79,20.01-88.9,0-40.58-7.15-68.84-22.5-88.92ZM143.48,265.08c-104.53,0-117.91-55.8-117.91-124.63S45.54,15.81,143.48,15.81s117.91,55.8,117.91,124.63-13.38,124.63-117.91,124.63Z"
        />
        <Path
          fill={color}
          d="M52.3,149.69c.56,0,1.54-.28,4.34-.28,3.22,0,3.78.28,4.97.28,1.05,0,1.26-.49,1.26-.91,0-.91-2.31-.84-3.36-1.05-2.31-.42-1.89-6.09-1.89-11.41,0-6.37.28-18.83.7-23.24.14-.98.14-1.54.42-1.54.35,0,.49.21.63.7,1.4,4.83,8.33,27.93,9.87,34.65.42,1.82.7,2.52,1.12,2.52,1.12,0,.63-1.82,3.43-11.27,5.39-18.13,6.93-23.94,7.56-25.76.21-.56.28-.84.56-.84.21,0,.21.42.28,1.4.35,7.21,1.05,23.59,1.05,30.94,0,2.8-.07,3.57-2.1,3.85-2.03.28-2.52.49-2.52,1.05v.07c0,.49.28.84,1.33.84.77,0,2.87-.28,5.6-.28,3.43,0,4.55.28,5.18.28.84,0,1.26-.14,1.26-.91,0-.63-.77-.7-2.45-1.05-1.05-.21-1.68-1.33-1.75-3.36-.28-7.91-1.33-27.79-1.33-32.83,0-3.22.35-4.2.98-4.27,2.17-.21,3.08-.42,3.08-1.19,0-.49-.42-.77-.98-.77-1.05,0-2.94.21-4.06.21-.91,0-2.38-.21-3.08-.21s-1.05.49-1.47,2.1c-2.66,9.38-5.32,18.76-7.98,28.14-.56,1.96-.77,2.66-1.26,2.66-.56,0-.84-1.68-1.4-3.57-2.8-9.45-5.6-18.97-8.4-28.42-.28-.84-.49-.91-1.26-.91s-2.45.21-3.36.21c-1.19,0-2.87-.21-4.06-.21-.63,0-1.33.07-1.33.84,0,.84,1.33.91,2.73,1.12,1.89.28,1.89,1.12,1.89,5.18,0,5.67-.49,23.1-.84,28.98-.21,3.78-.28,5.74-2.17,6.3-1.19.35-2.31.14-2.31,1.12,0,.84.49.84,1.12.84Z"
        />
        <Path
          fill={color}
          d="M95.61,128.97c1.12,0,2.03-.42,2.73-1.05.7-.7,1.19-1.68,1.47-2.59,1.05-3.85,2.73-6.72,6.51-6.72,2.03,0,2.94,1.12,2.94,4.83v3.99c0,1.47-.42,2.17-2.17,3.43-1.12.91-2.8,2.03-5.32,3.85-5.39,3.92-7.35,5.95-7.35,9.38,0,4.06,2.24,6.16,5.11,6.16s4.9-1.12,6.37-2.38c1.68-1.33,2.66-2.73,3.22-2.73.35,0,.49.42.63.98.35,1.47.91,3.85,3.85,3.85,4.06,0,5.6-4.27,5.6-5.53,0-.91-.21-1.12-.56-1.12-.49,0-.7.77-.98,1.54-.28.84-.91,1.61-2.24,1.61-1.26,0-1.96-.84-1.96-2.8v-21.91c0-1.75-.28-2.94-.98-3.64-.84-.91-2.24-1.19-4.48-1.19-8.26,0-13.86,6.72-13.86,10.5,0,1.12.35,1.54,1.47,1.54ZM109.26,140.52c0,1.96-.56,3.01-2.1,4.41s-3.01,2.38-4.62,2.38c-1.96,0-3.5-1.33-3.5-4.06v-.07c0-2.59,1.33-4.83,3.57-6.93,1.19-1.19,2.66-2.38,4.34-3.5,1.12-.77,1.47-.84,1.82-.84s.49.21.49.91v7.7Z"
        />
        <Path
          fill={color}
          d="M129.93,121.76c1.33-1.05,3.15-1.75,4.97-1.75s3.22.7,4.06,2.17c.7,1.12,1.05,2.66,1.05,4.69v17.22c0,3.22-.42,3.36-3.15,3.92-.7.14-1.05.63-1.05,1.05,0,.35.21.63,1.12.63,1.19,0,2.87-.28,5.11-.28,2.1,0,3.92.28,4.9.28.77,0,1.4-.14,1.4-.84,0-.56-.35-.7-1.26-.84-2.66-.42-2.87-.7-2.87-2.73v-19.39c0-1.75.77-3.22,1.96-4.27,1.26-1.05,2.87-1.61,4.62-1.61,3.5,0,5.81,1.89,5.81,6.16v17.92c0,3.01-.77,3.36-3.43,3.92-.7.14-1.33.49-1.33.91,0,.7.63.77,1.26.77,1.12,0,2.94-.28,5.11-.28,2.31,0,4.27.28,5.46.28.84,0,1.12-.21,1.12-.77,0-.35-.49-.56-1.75-.91-1.75-.49-2.24-1.05-2.24-3.29v-20.16c0-2.45-1.05-4.34-2.59-5.53-1.4-1.12-3.29-1.68-5.11-1.68-4.48,0-7.21,3.01-8.75,4.2-.35.28-.63.49-.84.49-.28,0-.42-.21-.7-.63-.77-1.26-1.89-4.06-6.51-4.06-2.17,0-3.99,1.12-5.53,2.24-1.4,1.12-2.52,2.24-3.15,2.24-.28,0-.35-.14-.35-2.1v-1.96c0-.49-.21-.84-.49-.84-.21,0-.49.28-.98.7-.84.7-2.24,1.75-4.55,2.8-.91.42-1.33.7-1.33,1.05,0,.63.63.56,1.4.63,1.89.14,2.1.35,2.1,2.24v20.02c0,2.73-.49,3.36-2.8,3.64-.63.07-.98.49-.98.84,0,.56.35.84,1.26.84.63,0,1.19-.28,3.64-.28,4.69,0,5.46.28,5.88.28.56,0,.98-.21.98-.7,0-.7-.35-.63-1.82-.98-1.68-.28-1.96-.84-1.96-2.45v-19.25c0-1.75.91-3.36,2.31-4.55Z"
        />
        <Path
          fill={color}
          d="M185.64,122.18c.7,1.12,1.05,2.66,1.05,4.69v17.22c0,3.22-.42,3.36-3.15,3.92-.7.14-1.05.63-1.05,1.05,0,.35.21.63,1.12.63,1.19,0,2.87-.28,5.11-.28,2.1,0,3.92.28,4.9.28.77,0,1.4-.14,1.4-.84,0-.56-.35-.7-1.26-.84-2.66-.42-2.87-.7-2.87-2.73v-19.39c0-1.75.77-3.22,1.96-4.27,1.26-1.05,2.87-1.61,4.62-1.61,3.5,0,5.81,1.89,5.81,6.16v17.92c0,3.01-.77,3.36-3.43,3.92-.7.14-1.33.49-1.33.91,0,.7.63.77,1.26.77,1.12,0,2.94-.28,5.11-.28,2.31,0,4.27.28,5.46.28.84,0,1.12-.21,1.12-.77,0-.35-.49-.56-1.75-.91-1.75-.49-2.24-1.05-2.24-3.29v-20.16c0-2.45-1.05-4.34-2.59-5.53-1.4-1.12-3.29-1.68-5.11-1.68-4.48,0-7.21,3.01-8.75,4.2-.35.28-.63.49-.84.49-.28,0-.42-.21-.7-.63-.77-1.26-1.89-4.06-6.51-4.06-2.17,0-3.99,1.12-5.53,2.24-1.4,1.12-2.52,2.24-3.15,2.24-.28,0-.35-.14-.35-2.1v-1.96c0-.49-.21-.84-.49-.84-.21,0-.49.28-.98.7-.84.7-2.24,1.75-4.55,2.8-.91.42-1.33.7-1.33,1.05,0,.63.63.56,1.4.63,1.89.14,2.1.35,2.1,2.24v20.02c0,2.73-.49,3.36-2.8,3.64-.63.07-.98.49-.98.84,0,.56.35.84,1.26.84.63,0,1.19-.28,3.64-.28,4.69,0,5.46.28,5.88.28.56,0,.98-.21.98-.7,0-.7-.35-.63-1.82-.98-1.68-.28-1.96-.84-1.96-2.45v-19.25c0-1.75.91-3.36,2.31-4.55,1.33-1.05,3.15-1.75,4.97-1.75s3.22.7,4.06,2.17Z"
        />
        <Path
          fill={color}
          d="M214.66,128.97c1.12,0,2.03-.42,2.73-1.05.7-.7,1.19-1.68,1.47-2.59,1.05-3.85,2.73-6.72,6.51-6.72,2.03,0,2.94,1.12,2.94,4.83v3.99c0,1.47-.42,2.17-2.17,3.43-1.12.91-2.8,2.03-5.32,3.85-5.39,3.92-7.35,5.95-7.35,9.38,0,4.06,2.24,6.16,5.11,6.16s4.9-1.12,6.37-2.38c1.68-1.33,2.66-2.73,3.22-2.73.35,0,.49.42.63.98.35,1.47.91,3.85,3.85,3.85,4.06,0,5.6-4.27,5.6-5.53,0-.91-.21-1.12-.56-1.12-.49,0-.7.77-.98,1.54-.28.84-.91,1.61-2.24,1.61-1.26,0-1.96-.84-1.96-2.8v-21.91c0-1.75-.28-2.94-.98-3.64-.84-.91-2.24-1.19-4.48-1.19-8.26,0-13.86,6.72-13.86,10.5,0,1.12.35,1.54,1.47,1.54ZM228.31,140.52c0,1.96-.56,3.01-2.1,4.41s-3.01,2.38-4.62,2.38c-1.96,0-3.5-1.33-3.5-4.06v-.07c0-2.59,1.33-4.83,3.57-6.93,1.19-1.19,2.66-2.38,4.34-3.5,1.12-.77,1.47-.84,1.82-.84s.49.21.49.91v7.7Z"
        />
        <Path
          fill={color}
          d="M98.11,60.34c.23.11.57.06,1.13-.02,2.3-.27,3.06.61,3.38,2.76l4.97,33.8c.24,1.66.12,2.25-1.21,2.87-.79.4-2.11.59-1.99,1.36.09.62.69.82,1.59.69,1.18-.17,3.35-.78,5.57-1.1,2.56-.38,5.1-.47,7.66-.84,4.29-.63,7.23-2.34,9-4.72,2.01-2.7,2.55-6.25,2.01-9.92-.92-6.23-4.74-9.14-9.12-10.05-1.03-.27-2.17-.32-3.24-.37l-.02-.14c4.41-2.28,7.7-5.87,6.95-10.93-.42-2.84-1.82-5.61-4.94-7.13-2.44-1.2-5.63-1.72-10.83-.96-3.12.46-6.17,1.33-9.03,2.6-.93.42-2.32,1.05-2.23,1.67.04.28.13.41.35.44ZM122.05,78.76c1.5,1.55,2.53,3.73,2.95,6.57.49,3.32.32,6.04-.57,8.15-1.15,2.79-3.46,4.4-6.85,4.9-3.74.55-5.24-1-5.67-3.91l-2.46-16.76c.06-.57.32-.68,4.34-1.27,3.46-.51,6.28.35,8.27,2.32ZM111.07,57.02c5.06-.74,7.88,2.59,8.61,7.58.58,3.95-.19,6.89-2.97,8.65-1.75,1.11-3.59,1.59-5.67,1.89-1.52.22-1.95.22-2.07-.62l-2.1-14.27c-.28-1.87.59-2.71,4.19-3.24Z"
        />
        <Path
          fill={color}
          d="M130.38,69.89c2.5.13,3.05.54,3.29,2.13l2.98,20.29c.36,2.42.06,2.82-2.37,3.6-.88.27-1.11.66-1.05,1.07.07.49.46.71.94.64.62-.09,1.41-.49,5.15-1.04,3.6-.53,4.4-.36,5.3-.5.83-.12,1.01-.36.94-.85-.08-.55-.52-.63-1.87-.72-2.5-.13-2.66-1.24-2.94-3.18l-3.43-23.34-.46-3.12c-.08-.55-.32-.73-.67-.68-.21.03-.51.36-1.01.86-.75.68-1.87,1.69-3.71,2.67-1.44.78-1.88,1.19-1.83,1.54.05.35.37.58.73.6Z"
        />
        <Path
          fill={color}
          d="M133.93,59.89h.07c1.39-.21,2.42-1.36,2.05-3.85-.36-2.42-1.69-3.29-3.07-3.09-1.32.19-2.41,1.42-2.05,3.84.37,2.49,1.76,3.28,3,3.1Z"
        />
        <Path
          fill={color}
          d="M144.02,73.62c.61,4.16,2.31,7.52,6.28,9.48,1.15.61,1.85.58,1.88.78.04.28-2.59,2.15-3.34,2.76-1.9,1.55-2.62,1.94-2.43,3.19.09.62,1.44,1.63,2.78,2.56,1.27.95,2.6,1.81,2.64,2.09.04.28-1.14.88-2.39,2.05-1.17,1.16-2.26,2.95-1.86,5.65.96,6.51,7.53,7.39,11.13,6.86,7.76-1.14,13.44-6.79,12.56-12.74-.84-5.68-4.05-8.32-12.23-7.12-1.59.23-2.89.57-4.41.79-1.32.19-3.54-.04-3.81-1.85-.22-1.52.26-2.09,7.53-5.56,4.17-1.96,5.71-6.43,4.99-11.28-.26-1.8-.75-3.14-1.19-4.21-.43-1-.8-1.58-.83-1.79-.04-.28.44-.35,1.14-.45l1.46-.21c1.38-.2,1.66-.74,1.54-1.57-.16-1.11-1.3-1.15-2.69-.95l-3.6.53c-1.04.15-1.67.17-2.42-.07-1.27-.45-2.69-.95-4.63-.66-5.89.87-8.82,6.89-8.12,11.67v.07ZM153.48,94.66c.51-.36,2.97-1,5.6-1.39,4.29-.63,7.74-.29,8.39,4.14.38,2.56-.21,4.84-1.52,6.52-1.31,1.68-3.33,2.83-5.9,3.2h-.07c-4.43.66-7.96-1.65-8.62-6.15-.28-1.87.2-4.91,2.11-6.33ZM151.97,63.18c3.19-.47,5.95,2.95,6.91,9.46.96,6.51-1.03,9.35-3.32,9.69h-.07c-3.19.48-5.94-2.87-6.9-9.38-.96-6.51,1.02-9.42,3.38-9.77Z"
        />
        <Path
          fill={color}
          d="M159.47,170.23c.63-.14,1.12-.28,1.12-.84,0-.63-.28-.84-.91-.84-1.26,0-2.1.28-3.01.28-2.03,0-3.64-.28-4.13-.28-.7,0-1.05.28-1.05.98,0,.63.77.63,1.33.7,1.89.21,2.59.56,2.59,1.54,0,.77-.07,1.4-1.12,5.18-1.12,4.13-4.41,15.12-4.9,15.12s-3.57-9.94-4.9-14.56c-.98-3.36-1.47-4.76-1.47-5.67,0-.77.28-1.4,1.47-1.61,1.47-.28,2.24-.28,2.24-.84s-.28-.84-1.19-.84c-.63,0-2.66.28-3.99.28-1.61,0-3.29-.28-4.9-.28-.7,0-1.05.21-1.05.63,0,.77.35.91,1.47,1.05.7.07,1.33.42,2.66,4.48,2.1,6.44,4.06,13.02,6.16,19.53.98,3.08,1.19,3.64,1.19,5.39,0,.98-.63,3.22-1.33,5.25-.7,2.03-1.47,3.92-1.75,4.2-.63.63-1.68.7-2.73.91-1.05.21-2.03.7-2.03,2.1v.07c0,2.73.28,3.43,2.94,3.43,1.75,0,2.31-.84,3.29-3.92.49-1.82,1.12-4.48,2.24-8.33,2.73-9.38,5.88-18.69,8.89-27.93,1.19-3.64,1.82-4.97,2.87-5.18Z"
        />
        <Path
          fill={color}
          d="M179.1,192c-.49,0-.63.98-1.19,2.38-.98,2.52-3.01,3.99-6.3,3.99-4.9,0-8.26-5.32-8.26-13.65,0-.91.42-1.33,1.61-1.33h12.81c1.68,0,1.68-.21,1.68-4.34,0-7-3.78-11.06-8.82-11.06-6.02,0-11.9,5.6-11.9,17.36,0,9.52,4.48,15.96,12.18,15.96,3.36,0,6.58-2.03,7.91-5.18.42-.98.77-2.17.77-3.29,0-.7-.21-.84-.49-.84ZM163.49,179.89c0-5.74,2.94-10.22,6.72-10.22,3.08,0,5.32,2.8,5.32,7.63,0,1.89-.28,2.66-.91,3.15-.42.35-1.96.7-4.41.84-1.05.07-2.24.14-3.64.14-2.94,0-3.08-.28-3.08-1.47v-.07Z"
        />
        <Path
          fill={color}
          d="M206.68,194.38c-.49,0-.7.77-.98,1.54-.28.84-.91,1.61-2.24,1.61-1.26,0-1.96-.84-1.96-2.8v-21.91c0-1.75-.28-2.94-.98-3.64-.84-.91-2.24-1.19-4.48-1.19-8.26,0-13.86,6.72-13.86,10.5,0,1.12.35,1.54,1.47,1.54s2.03-.42,2.73-1.05c.7-.7,1.19-1.68,1.47-2.59,1.05-3.85,2.73-6.72,6.51-6.72,2.03,0,2.94,1.12,2.94,4.83v3.99c0,1.47-.42,2.17-2.17,3.43-1.12.91-2.8,2.03-5.32,3.85-5.39,3.92-7.35,5.95-7.35,9.38,0,4.06,2.24,6.16,5.11,6.16s4.9-1.12,6.37-2.38c1.68-1.33,2.66-2.73,3.22-2.73.35,0,.49.42.63.98.35,1.47.91,3.85,3.85,3.85,4.06,0,5.6-4.27,5.6-5.53,0-.91-.21-1.12-.56-1.12ZM197.3,191.58c0,1.96-.56,3.01-2.1,4.41s-3.01,2.38-4.62,2.38c-1.96,0-3.5-1.33-3.5-4.06v-.07c0-2.59,1.33-4.83,3.57-6.93,1.19-1.19,2.66-2.38,4.34-3.5,1.12-.77,1.47-.84,1.82-.84s.49.21.49.91v7.7Z"
        />
        <Path
          fill={color}
          d="M222.48,167.99c-2.38,0-3.71,1.54-4.69,3.01-.98,1.47-1.4,3.01-1.96,3.01-.28,0-.28-.91-.28-1.96,0-1.26,0-2.31-.07-3.01-.07-.7-.28-1.05-.56-1.05-.56,0-.84.49-1.82,1.54-1.33,1.4-2.66,1.89-3.57,2.17-.7.21-1.12.42-1.12,1.05,0,.42.21.56.7.56,2.03,0,2.73.49,2.73,3.01v18.97c0,3.22-.35,3.5-2.31,3.78-.91.14-1.47.28-1.47.98,0,.49.49.7,1.33.7.49,0,1.26-.28,4.48-.28,4.2,0,4.83.28,5.39.28,1.26,0,1.61-.14,1.61-.77,0-.49-.77-.63-2.31-.91-2.38-.42-2.52-1.26-2.52-4.48v-14.56c0-1.19.35-3.08,1.05-5.04s1.75-3.64,3.22-3.64c.77,0,.77,1.19.77,2.31,0,1.54.56,2.8,2.45,2.8s2.59-1.61,2.59-3.85c0-2.87-1.33-4.62-3.64-4.62Z"
        />
        <Path
          fill={color}
          d="M237.53,181.78c-3.43-2.17-6.79-3.92-6.79-7.28,0-2.73,2.24-4.83,5.25-4.83,2.17,0,3.22,1.4,3.99,3.29,1.05,2.66,1.05,3.71,1.75,3.71.49,0,.63-.56.7-1.68v-1.96c0-1.19,0-2.1-.07-2.73-.07-.7-.14-1.05-.35-1.19-1.19-.77-3.01-1.12-5.25-1.12-4.48,0-9.52,2.31-9.52,8.33v.07c0,5.25,3.29,7.42,6.58,9.38,3.22,1.96,6.51,3.78,6.51,8.26,0,3.29-2.1,5.6-5.39,5.6-2.59,0-4.2-2.03-5.18-4.9-1.12-3.36-.98-4.06-1.68-4.06-.56,0-.77.56-.77,4.13,0,1.75.07,2.94.14,3.78.14,1.47.42,1.75.84,1.89s.84.21,1.33.35c.98.28,2.24.49,4.2.49,6.3,0,10.43-3.71,10.43-10.01,0-4.9-3.36-7.42-6.72-9.52Z"
        />
        <Path
          fill={color}
          d="M76.54,210.01c-2.07.31-3.76-.36-5.09-1.28-1.55-1.09-2.8-2.75-3.19-5.34l-5.25-34.59-24.31,3.69.45,2.97,5.69-.86c1.89-.22,3.21-.22,4.39.59,1.41.98,1.85,2.17,2.19,4.43l3.9,25.73c.39,2.59-.3,4.54-1.46,6.04-.99,1.34-2.41,2.42-4.48,2.73l-4.27.65.45,2.97,35.69-5.41-.45-2.97-4.27.65Z"
        />
        <Path
          fill={color}
          d="M115.02,168.26c-5.29-3.89-11.95-5.93-17.89-5.02-5.95.9-11.7,4.82-15.6,10.1-4.39,5.96-5.58,10.77-4.71,16.52s3.44,10,9.39,14.38c5.29,3.89,11.95,5.93,17.89,5.02,5.95-.9,11.7-4.82,15.6-10.1,4.39-5.96,5.58-10.77,4.7-16.52-.87-5.75-3.43-10-9.39-14.38ZM109.24,203.4c-1.56,1.89-3.32,2.55-5.45,2.88-2.13.32-4.26.25-6.32-1.09-2.34-1.5-3.96-3.9-4.63-8.29l-2.8-18.49c-.67-4.4.17-7.17,1.96-9.29,1.57-1.89,3.58-2.59,5.71-2.92,2.13-.32,4-.21,6.06,1.13,2.34,1.5,3.97,3.9,4.63,8.29l2.8,18.49c.67,4.4-.17,7.17-1.96,9.29Z"
        />
      </G>
    </Svg>
  )
}
