// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'
import { Product } from '../product'

interface Props {
  data: Menu['food']
  color?: string
  fontSizePercentage?: number
  specialProductSVG?: string
}

export const Products: React.FC<Props> = ({ data, color, fontSizePercentage, specialProductSVG }) => {
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`

  const styles = StyleSheet.create({
    section: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '3px',
      textAlign: 'left',
      margin: '0px 2px'
    },
    line: {
      borderBottom: `1px solid ${color}`
    },
    flex: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column'
    },
    wrap: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column'
    },
    label: {
      fontFamily: 'pinyon',
      fontSize: adjustFontSize(17),
      marginBottom: '4px'
    },
    subtitle: {
      textTransform: 'uppercase',
      fontSize: adjustFontSize(7),
      fontFamily: 'prestige-elite-bold',
      marginBottom: '12px',
      marginTop: '4px',
      maxWidth: '70%',
      letterSpacing: '0.5px'
    }
  })
  return (
    <View style={styles.section}>
      {data.map((item) => {
        return (
          <View key={item.order} wrap={false}>
            <View style={styles.flex}>
              <View>
                <Text style={styles.label}>{item.label}</Text>
                <View style={styles.line}></View>
                <View
                  style={{
                    marginTop: '0.5px',
                    ...styles.line
                  }}
                ></View>
              </View>
              <Text style={styles.subtitle}>{item.subtitle}</Text>
            </View>
            <View style={styles.wrap}>
              {item.products?.map((product, idx) => (
                <Product
                  product={product}
                  idx={idx}
                  key={product.label}
                  color={color}
                  fontSizePercentage={FONT_SIZE_RATIO}
                  specialProductSVG={specialProductSVG}
                />
              ))}
            </View>
          </View>
        )
      })}
    </View>
  )
}
