import { useSearchParams } from 'react-router-dom'

export default function ReservationButton({
  reservationUrl,
  label
}: {
  reservationUrl: string | null | undefined
  label: string | null | undefined
}) {
  const [searchParams] = useSearchParams()
  const hideReservation = searchParams.get('hide_reservation')

  if (hideReservation === 'true' || !reservationUrl || !label) {
    return null
  }

  return (
    <a
      href={reservationUrl}
      rel="noopener noreferrer"
      target="_blank"
      className={`fixed text-primary border-primary py-3 text-[13px] font-saaFD uppercase transform -translate-x-1/2
      bg-white border rounded-sm shadow-lg px-7 bottom-6 left-1/2
      hover:scale-105 active:scale-95 no-underline
      transition-transform ease-out duration-300`}
    >
      {label}
    </a>
  )
}
