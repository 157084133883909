import React, { useEffect, useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import slugify from 'slugify'
import { useSearchParams } from 'react-router-dom'

import { Enum_Componentcategoriescategory_Gif_Placement } from 'api/graphql/types.generated'
import { Product } from '../product'
import { renderPlacement } from './utils'
import { ProductType, TagType } from 'type/menu'
import SubCategory from '../subCategory'
import { FilterButton } from '../filterButton'
import { FilterModal } from '../filterModal'
import { filterProductByTags } from '../../../utils/filters'
import { ScrollToTopButton } from '../../scrollToTop'
import { normalizeString } from '../../../utils/string'

interface Props {
  title: string | undefined | null
  subtitle: string | undefined | null
  description: string | undefined | null
  footerDescription: string | undefined | null
  image: string | undefined | null
  imagePlacement: Enum_Componentcategoriescategory_Gif_Placement | null | undefined
  products: ProductType[]
  backgroundColor?: string | null
  hideProducts?: boolean | null | undefined
  hidden_products: number[]
  filterTitleSection1: string
  filterTitleSection2: string
  showFilters: boolean
  noMatchMessage?: string
}

export const Category: React.FC<Props> = ({
  title,
  subtitle,
  description,
  footerDescription,
  image,
  imagePlacement,
  products,
  backgroundColor,
  hideProducts,
  filterTitleSection1,
  filterTitleSection2,
  showFilters,
  hidden_products,
  noMatchMessage
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isCategoryOpen, setIsCategoryOpen] = useState<boolean>(false)
  const [currentSubCategoryTitle, setCurrentSubCategoryTitle] = useState<string | null>(null)

  const productsHaveSubCategoriesAccordion = products.some(
    (product) =>
      product.sub_category?.attributes?.title !== null && product.sub_category?.attributes?.is_accordion === true
  )
  const query = searchParams.get('categorie')

  const slug = slugify(title ?? '', { lower: true })
  const categoriesTitles: string[] = []
  const categories: string[] = []

  const subCategory = searchParams.get('sous_categorie')
  const decodedSubCategory = decodeURIComponent(subCategory || '')

  useEffect(() => {
    const productFound = products.find(
      (product) => slugify(product.sub_category.attributes.title ?? '', { lower: true }) === decodedSubCategory
    )

    if (productFound) {
      setCurrentSubCategoryTitle(productFound.sub_category.attributes.title)
    }
  }, [decodedSubCategory, products])

  useEffect(() => {
    if (slug === query) {
      setIsCategoryOpen(true)
    } else {
      setIsCategoryOpen(false)
    }
  }, [query, slug])

  function scrollToElement(elementId: string, offset = 0) {
    const element = document.getElementById(elementId)
    const container = document.getElementById('main')

    if (!element || !container) {
      // eslint-disable-next-line no-console
      console.warn(`Element with ID ${elementId} not found.`)
      return
    }

    // element.getBoundingClientRect() - The size of an element and it's position relative to the viewport
    // window.scrollY -   gives you the scroll position of the document in pixels from the top of the viewport
    const elementPosition = element.getBoundingClientRect().top + container.scrollTop - offset

    container.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    if (!isCategoryOpen) {
      return
    }

    const stickyCategory = document.querySelector(`#${query}_sticky_element`) as HTMLElement

    const timeoutId = setTimeout(() => {
      stickyCategory.scrollIntoView({
        inline: 'start',
        block: 'nearest'
      })
      scrollToElement(query as string, 95)
    }, 60)

    return () => clearTimeout(timeoutId)
  }, [isCategoryOpen, query])

  const setParams = () => {
    const queryService = searchParams.get('service')
    const querySubCategory = searchParams.get('sous_categorie')
    const queryHideReservation = searchParams.get('hide_reservation')
    let params = {}

    if (queryService) {
      params = { ...params, service: queryService }
    }

    if (querySubCategory) {
      params = { ...params, sous_categorie: querySubCategory }
    }

    if (queryHideReservation) {
      params = { ...params, hide_reservation: queryHideReservation }
    }

    if (searchParams.get('categorie') === slug) {
      setSearchParams(params, {})
    } else {
      setSearchParams({ ...params, categorie: slug })
    }
  }

  const [filteredTags, setFilteredTags] = useState<{ marketingTitles: string[]; titles: string[] }>({
    marketingTitles: [],
    titles: []
  })
  const [marketingTitles, setMarketingTitles] = useState<string[]>([])
  const [titles, setTitles] = useState<string[]>([])
  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false)

  const thereAreTagsToFilter = marketingTitles.length > 0 || titles.length > 0
  const numberOfActivatedTags = filteredTags.marketingTitles.length + filteredTags.titles.length
  const { products: filteredProducts, isPerfectMatch } = filterProductByTags(products, filteredTags)
  const allFilteredTags = [...filteredTags.marketingTitles, ...filteredTags.titles]

  useEffect(() => {
    const tags = products.map((category) => category.product.attributes.tags).flat()

    const marketingTitles = tags.reduce((acc: string[], tag: TagType) => {
      if (acc.indexOf(tag.marketing_title) < 0) {
        acc.push(tag.marketing_title)
      }
      return acc
    }, [])
    const titles = tags.reduce((acc: string[], tag: TagType) => {
      if (acc.indexOf(tag.title) < 0) {
        acc.push(tag.title)
      }
      return acc
    }, [])

    setMarketingTitles(marketingTitles)
    setTitles(titles)
  }, [products, title])

  const showFilterModal = () => {
    setIsFilterModalOpened(true)
  }

  const handleOnFilter = ({ marketingTitles, titles }: { marketingTitles: string[]; titles: string[] }) => {
    setFilteredTags({
      marketingTitles,
      titles
    })

    const element = document.getElementById(`sub_category_${title}`)
    if (element) {
      const scroll = setTimeout(() => {
        element.scrollIntoView(true)
        clearTimeout(scroll)
      }, 10)
    }
  }

  if (hideProducts ?? false) {
    return <></>
  }

  return (
    <div
      id={slug}
      //  style={{ scrollMarginTop: 95 }}
      key={`disclosure-${slug}-${isCategoryOpen}`}
    >
      <Disclosure defaultOpen={isCategoryOpen}>
        <>
          {image && (
            <div className="relative top-[95px]">
              <img
                src={image}
                alt="gifs"
                className={`absolute z-30 w-[90px] aspect-square ${renderPlacement(imagePlacement)} zIndex`}
              />
            </div>
          )}
          <Disclosure.Button onClick={setParams} className={`w-full z-20 button-category`}>
            <div
              id={`${normalizeString(title + '')}_category`}
              className="flex flex-col items-center justify-center min-h-[74px] p-3 border-b border-white bg-primary"
              style={{ backgroundColor: backgroundColor ?? '' }}
            >
              <p translate="no" className="text-2xl text-white uppercase break-words font-saaFD">
                {title}
              </p>
              {subtitle && (
                <p className="text-[11px] mt-2.5 leading-[12px] text-white font-prestige whitespace-pre-wrap">
                  {subtitle}
                </p>
              )}
            </div>
          </Disclosure.Button>

          <Transition
            enter="transition duration-500 ease-out"
            enterFrom="transform  opacity-0"
            enterTo="transform  opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform  opacity-0"
          >
            <Disclosure.Panel className="relative w-full -mt-[2px]">
              <div className="relative text-center text-primary font-prestige">
                {description && (
                  <div className="px-[30px] p-[28px] pb-[28px]">
                    <p className="whitespace-pre-wrap text-[11px] -tracking-[0.35px] leading-[12px] pt-[4px]">
                      {description}
                    </p>
                  </div>
                )}

                {allFilteredTags.length > 0 && !isPerfectMatch && noMatchMessage && (
                  <p className="text-primary text-[13px] py-10 font-prestige px-5">{noMatchMessage}</p>
                )}
                {thereAreTagsToFilter && showFilters && !productsHaveSubCategoriesAccordion && (
                  <FilterButton
                    parentId="sticky-header"
                    onClick={showFilterModal}
                    numberOfActivatedTags={numberOfActivatedTags}
                  />
                )}
                <ScrollToTopButton />
                {isFilterModalOpened && (
                  <FilterModal
                    marketingTitles={marketingTitles}
                    titles={titles}
                    onClose={() => setIsFilterModalOpened(false)}
                    onFilter={handleOnFilter}
                    activatedMarketingTitles={filteredTags.marketingTitles}
                    activatedTitles={filteredTags.titles}
                    section1Title={filterTitleSection1}
                    section2Title={filterTitleSection2}
                    parentId={'sticky-header'}
                  />
                )}

                {filteredProducts?.map((product, idx: number) => {
                  const isAccordion = Boolean(product.sub_category?.attributes?.is_accordion)

                  if (isAccordion) {
                    const categoryTitle = product.sub_category?.attributes?.title
                    const selected = products?.filter((p) => p?.sub_category?.attributes?.title === categoryTitle)

                    if (categoryTitle && !categoriesTitles.includes(categoryTitle)) {
                      categoriesTitles.push(categoryTitle)

                      return (
                        <SubCategory
                          key={product?.sub_category?.id}
                          title={categoryTitle}
                          description={product.sub_category?.attributes?.description}
                          products={selected}
                          hiddenProductIds={hidden_products}
                          backgroundColor={backgroundColor}
                          isOpen={currentSubCategoryTitle === categoryTitle}
                          onClick={(subCategoryTitle) => {
                            setCurrentSubCategoryTitle(subCategoryTitle)
                          }}
                          noMatchMessage={noMatchMessage}
                        />
                      )
                    } else return <></>
                  }

                  const title = product?.sub_category?.attributes?.title

                  const showCategory = () => {
                    if (categories.includes(title)) {
                      return false
                    } else {
                      if (product?.sub_category?.attributes?.title === null) {
                        return false
                      } else {
                        categories.push(product?.sub_category?.attributes?.title!)
                        return true
                      }
                    }
                  }

                  return (
                    <div id="product" key={product.product?.id} className="px-[23px]">
                      <Product
                        removeTopBorder={!!description && idx === 0 && !product.sub_category.attributes.title}
                        hidden_products={hidden_products}
                        product={product?.product}
                        subCategory={product.sub_category}
                        idx={idx}
                        showCategory={showCategory()}
                        filteredTags={allFilteredTags}
                        isPerfectMatchFilter={isPerfectMatch}
                      />
                    </div>
                  )
                })}

                {/* Catégory Footer */}
                {footerDescription && (
                  <div className="px-[30px]">
                    <p className="whitespace-pre-wrap py-[20px] text-[12px] leading-[12px] text-center font-prestige">
                      {footerDescription}
                    </p>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      </Disclosure>
    </div>
  )
}
