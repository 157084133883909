// @ts-nocheck
import { Page, View, Document, StyleSheet, Font, Text } from '@react-pdf/renderer'
import { Header } from './header'
import { Menu } from 'type'
import { Products } from './products'
import { Footer } from './footer'
import { Birra } from './birra'

import saa from 'fonts/saa.ttf'
import prestigeBold from 'fonts/p-bold.ttf'
import prestigeEliteBold from 'fonts/prestige-elite-m-bold-webfont.ttf'

import prestige from 'fonts/prestige.ttf'
import times from 'fonts/times.woff'
import brioche from 'fonts/brioche-regular.otf'
import pinyon from 'fonts/PinyonScript-Regular.ttf'
import minion from 'fonts/MinionPro-Regular.otf'
import saaBold from 'fonts/saa-bold.otf'
import saaFd from 'fonts/SaaSeriesFD.woff'
import saaEd from 'fonts/saa-e-d.otf'

import { PricePhilosohpy } from './pricePhilosohpy'
Font.register({
  family: 'saa-bold',
  format: 'truetype',
  fonts: [{ src: saaBold, fontWeight: 'bold' }]
})
Font.register({
  family: 'saa-f-d',
  format: 'truetype',
  fonts: [{ src: saaFd, fontWeight: 'regular' }]
})
Font.register({
  family: 'saa-e-d',
  format: 'truetype',
  fonts: [{ src: saaEd, fontWeight: 'regular' }]
})
Font.register({
  family: 'brioche',
  format: 'truetype',
  fonts: [{ src: brioche }]
})
Font.register({
  family: 'minion',
  format: 'truetype',
  fonts: [{ src: minion, fontWeight: 'bold' }]
})
Font.register({
  family: 'pinyon',
  format: 'truetype',
  fonts: [{ src: pinyon }]
})
Font.register({
  family: 'saa',
  format: 'truetype',
  fonts: [{ src: saa, fontWeight: 'bold' }]
})
Font.register({
  family: 'prestige-bold',
  format: 'truetype',
  fonts: [{ src: prestigeBold, fontWeight: 'bold' }]
})
Font.register({
  family: 'prestige-elite-bold',
  format: 'truetype',
  fonts: [{ src: prestigeEliteBold, fontWeight: 'bold' }]
})
Font.register({
  family: 'prestige',
  format: 'truetype',
  fonts: [{ src: prestige }]
})
Font.register({
  family: 'times',
  fonts: [{ src: times }]
})

export const PdfRenderer = ({
  data,
  onRender,
  color,
  fontSizePercentage,
  categoriesCount,
  wineCategoriesCount,
  philosophyPositionOnLeft,
  hideFooterOnVerso,
  specialProductSVG
}: {
  data: Menu
  onRender: () => void
  color?: string
  fontSizePercentage?: number
  categoriesCount?: number
  wineCategoriesCount?: number
  philosophyPositionOnLeft?: boolean
  hideFooterOnVerso?: boolean
  specialProductSVG?: string
}) => {
  if (!data) return <></>

  const COLOR = color || '#252525'
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`

  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      width: '100%',
      color: `${COLOR}`,
      fontSize: adjustFontSize(7.5),
      fontFamily: 'prestige',
      padding: '20px 40px'
    },
    border: {
      borderRight: `1px solid ${COLOR}`,
      borderLeft: `1px solid ${COLOR}`,
      width: '100%'
    },
    row: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0 auto'
    },
    section: {
      width: '50%'
    },
    footer: {
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      bottom: 0
    },
    note: {
      border: `1px solid ${COLOR}`,
      padding: '10px',
      width: '100%',
      margin: '0 auto',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: adjustFontSize(6),
      fontFamily: 'saa'
    },
    philosophy: {
      width: '100%',
      margin: '20px auto 0 auto',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: adjustFontSize(6),
      fontFamily: 'prestige'
    },
    philosophyTitle: {
      fontFamily: 'prestige-bold',
      marginBottom: '5px',
      textDecoration: 'underline'
    }
  })

  const maxCategories = categoriesCount === 0 ? 3 : categoriesCount
  const maxWineCategories = wineCategoriesCount === 0 ? 1 : wineCategoriesCount

  return (
    <Document
      title={`${data.label}-${data.season}-${data.service} `}
      onRender={() => {
        onRender()
      }}
      author="BigMamma"
      subject="Menu"
      language="fr"
    >
      <Page size="A3" style={styles.page}>
        <View>
          <Header data={data} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
          <View style={styles.row}>
            <View style={[styles.section, { paddingRight: '10px' }]}>
              <Products
                data={data.food?.slice(0, maxCategories)}
                color={COLOR}
                fontSizePercentage={FONT_SIZE_RATIO}
                specialProductSVG={specialProductSVG}
              />
            </View>
            <View
              style={{
                width: '20px'
              }}
            />
            <View style={{ ...styles.section }}>
              <Products
                data={data.food?.slice(maxCategories, 8)}
                color={COLOR}
                fontSizePercentage={FONT_SIZE_RATIO}
                specialProductSVG={specialProductSVG}
              />
            </View>
          </View>

          <View fixed style={styles.footer}>
            <PricePhilosohpy data={data} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
            <Footer data={data} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
          </View>
        </View>
      </Page>
      <Page size="A3" style={styles.page}>
        <View>
          <View fixed style={styles.top}></View>
          <Birra data={data} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
          <View style={styles.row}>
            <View style={[styles.section, { paddingRight: '10px' }]}>
              <Products
                data={data.beverage?.slice(maxWineCategories, 20)}
                color={COLOR}
                fontSizePercentage={FONT_SIZE_RATIO}
                specialProductSVG={specialProductSVG}
              />
              {philosophyPositionOnLeft ? (
                <Philososphy data={data} styles={styles} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
              ) : null}
            </View>
            <View
              style={{
                width: '20px'
              }}
            />
            <View style={styles.section}>
              <Products
                data={data.beverage?.slice(0, maxWineCategories)}
                color={COLOR}
                fontSizePercentage={FONT_SIZE_RATIO}
                specialProductSVG={specialProductSVG}
              />
              {philosophyPositionOnLeft ? null : (
                <Philososphy data={data} styles={styles} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
              )}
            </View>
          </View>
          {!hideFooterOnVerso && (
            <View fixed style={styles.footer}>
              <Footer data={data} color={COLOR} fontSizePercentage={FONT_SIZE_RATIO} />
            </View>
          )}
        </View>
      </Page>
    </Document>
  )
}

function Philososphy({ data, styles, color }) {
  return (
    <>
      <View
        style={{
          borderTop: `1px solid ${color}`,
          width: '100%',
          margin: '10px auto 2px auto'
        }}
      ></View>
      <View style={styles.note}>
        <Text>{data.note}</Text>
      </View>
      <View style={styles.philosophy}>
        <Text style={styles.philosophyTitle}>{data.philosophy.label}</Text>
        <Text>{data.philosophy.description}</Text>
      </View>
    </>
  )
}
