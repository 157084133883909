import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Menu } from 'type'

export interface MenuState {
  json?: {
    fileName: string
    data: Menu
    color?: string
    fontSizePercentage?: number
    categoriesCount?: number
    wineCategoriesCount?: number
    philosophyPositionOnLeft?: boolean
    hideFooterOnVerso?: boolean
    specialProductSVG?: string
  }
  isLoading: boolean
  isLoaded: boolean
  isGenerating: boolean
  isGenerated: boolean
  error: string
}

const initialState: MenuState = {
  isLoading: false,
  isLoaded: false,
  isGenerating: false,
  isGenerated: false,
  error: ''
}

// ---ACTIONS

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<MenuState['json']>) => {
      state.json = action.payload
      state.isLoading = true
    },
    loaded: (state) => {
      state.isLoading = false
      state.isLoaded = true
    },
    reset: () => initialState
  }
})

export const { load, loaded, reset } = menuSlice.actions

export default menuSlice.reducer
