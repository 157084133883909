import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import slugify from 'slugify'

export const StickyCategories = ({ categories }: { categories: any }) => {
  const [selectedCategory, setSelectedCategory] = useState<{ slug: string; title: string } | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const categoriesObjects = categories.map((category: any) => {
    return {
      title: category.category?.attributes?.title,
      slug: slugify(category.category?.attributes?.title, { lower: true })
    }
  })

  useEffect(() => {
    if (!searchParams.get('categorie')) return
    const matchedCategory = categoriesObjects.find((category: any) => category.slug === searchParams.get('categorie'))
    setSelectedCategory(matchedCategory)
  }, [searchParams])

  const handleCategoryClick = (category: any) => {
    if (!category) return

    const queryService = searchParams.get('service')
    const querySubCategory = searchParams.get('sous_categorie')
    const queryHideReservation = searchParams.get('hide_reservation')

    setSelectedCategory(category.slug)

    let params = {}

    if (queryService) {
      params = { ...params, service: queryService }
    }

    if (querySubCategory) {
      params = { ...params, sous_categorie: querySubCategory }
    }

    if (queryHideReservation) {
      params = { ...params, hide_reservation: queryHideReservation }
    }

    if (searchParams.get('categorie') === category.slug) {
      setSelectedCategory(null)
      setSearchParams(params, {})
    } else {
      setSelectedCategory(category)
      setSearchParams({ ...params, categorie: category.slug })
    }
  }

  return (
    <>
      {searchParams.get('categorie') && (
        <div
          id="sticky-header"
          className="sticky z-50 flex w-full overflow-x-scroll h-14 top-10 border-y text-primary border-primary hide-scrollbar"
        >
          {categoriesObjects.map((category: any, index: number) => (
            <div
              key={index}
              translate="no"
              id={`${category.slug}_sticky_element`}
              onClick={() => handleCategoryClick(category)}
              className={`whitespace-nowrap tracking-widest cursor-pointer flex items-center text-[13px] font-saaFD px-4 py-1
                        ${selectedCategory?.slug === category.slug ? 'bg-primary text-white' : 'bg-white text-primary'}
                        border-primary border-r`}
            >
              {category.title}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
